import React from 'react';
import { Link } from 'react-router-dom';

const Plans = () => {
    return (
        <>
            <section className="starta-plans" id="plan">
                <div className="container">
                    <h1 className="starta-heading mx-auto">Choose a Plan</h1>
                    <h1 className="starta-h1 text-center">
                        Choice The Right Pricing Plan That Suits your Need
                    </h1>
                    <div className="row justify-content-center">
                        <div className="col-md-4 lap-sm-50 tab-100">
                            {/* plan single */}
                            <div className="plan">
                                <div className="row align-items-center">
                                    <div className="col-md-5 col-sm-3 col-5">
                                        <i className="starta-icon featureIcon fa-regular fa-lightbulb" />
                                    </div>
                                    <div className="col-md-7 p-md-0 col-sm-9 col-7">
                                        <article className="plan-heading">
                                            <h3 className="starta-h2">Basic Plan</h3>
                                            <p className="starta-h2">
                                                <span>$</span>199/monthly
                                            </p>
                                        </article>
                                    </div>
                                    <p className="starta-desc fw-medium my-4">
                                    Enhance your website with our Basic Plan! Get expert one-page optimization, improve rankings, and attract more traffic for better visibility and performance.
                                    </p>
                                    <button className="starta-button">
                                        <a href="/contact">Get Started</a>
                                    </button>
                                    <ul className="starta-list list-unstyled">
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            One Page Optimization
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Ranking Guaranteed
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Weekly SEO Update Report
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-4 lap-sm-50 tab-100">
                            {/* plan single */}
                            <div className="plan best">
                                <div className="row align-items-center">
                                    <div className="col-md-5 col-sm-3 col-5">
                                        <i className="starta-icon featureIcon fa-regular fa-lightbulb" />
                                    </div>
                                    <div className="col-md-7 p-md-0 col-sm-9 col-7">
                                        <article className="plan-heading">
                                            <h3 className="starta-h2">Standard Plan</h3>
                                            <p className="starta-h2">
                                                <span>$</span>399/monthly
                                            </p>
                                        </article>
                                    </div>
                                    <p className="starta-desc fw-medium my-4">
                                    Optimize five critical pages with our Standard Plan. Boost your site's SEO, increase user engagement, and achieve higher rankings for long-term online success.
                                    </p>
                                    <ul className="starta-list list-unstyled">
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            5 Pages Optimization
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Ranking Guaranteed
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Weekly SEO Update Report
                                        </li>
                                    </ul>
                                    <button className="starta-button">
                                        <Link to="/contact" className="position-relative">
                                            Get Started
                                        </Link>
                                        <span className="starta-button-hover" />
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-md-4 lap-sm-50 tab-100">
                            {/* plan single */}
                            <div className="plan">
                                <div className="row align-items-center">
                                    <div className="col-md-5 col-sm-3 col-5">
                                        <i className="starta-icon featureIcon fa-regular fa-lightbulb" />
                                    </div>
                                    <div className="col-md-7 p-md-0 col-sm-9 col-7">
                                        <article className="plan-heading">
                                            <h3 className="starta-h2">Advanced Plan</h3>
                                            <p className="starta-h2">
                                                <span>$</span>699/monthly
                                            </p>
                                        </article>
                                    </div>
                                    <p className="starta-desc fw-medium my-4">
                                    Transform your entire website with our Advanced Plan. Comprehensive optimization ensures peak performance, improved rankings, and enhanced visibility across search engines.
                                    </p>
                                    <button className="starta-button">
                                        <a href="/contact">Get Started</a>
                                    </button>
                                    <ul className="starta-list list-unstyled">
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Whole Website Optimization
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Ranking Guaranteed
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Weekly SEO Update Report
                                        </li>
                                    </ul>
                                </div>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Plans;