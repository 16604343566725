import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState({});
  const [footer, setFooter] = useState({});
  const [social, setSocial] = useState({});
  useEffect(() => {
    fetch(`https://one-seo-audit-9463501139a9.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://one-seo-audit-9463501139a9.herokuapp.com/footer-links`)
      .then((res) => res.json())
      .then((info) => setFooter(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://one-seo-audit-9463501139a9.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setSocial(info[0]));
  }, []);

  const [contact, setContact] = useState({});

  useEffect(() => {
    fetch(`https://one-seo-audit-9463501139a9.herokuapp.com/contact/`)
      .then((res) => res.json())
      .then((info) => setContact(info[0]));
  }, []);

  return (
    <>
      <footer className="starta-footer pt-5">
        <div className="container py-5">
          <div className="row">
            {/* block 1 */}
            <div className="col-md-5 border-end">
              <h1 className="starta-h1">Let's Connect.</h1>
              <p className="starta-desc">
              We provide expert SEO audits and proven strategies to enhance your website's performance, boost rankings, and drive organic traffic.
              </p>
              <ul className="starta-social list-unstyled">
                <li>
                  <a href="https://youtube.com/@1SEOaudit">
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
                
                <li>
                  <a href="https://www.facebook.com/1SEOAudit">
                    <i className="fa-brands fa-square-facebook" />
                  </a>
                </li>
              </ul>
            </div>
         
            {/* block 4 */}
            <div className="col-md-3 lap-sm-100">
              <h4 className="starta-h4 mb-4 lap-sm-none">Contact Us</h4>
             <p className="text-white"><i className="fa-solid fa-envelope" /> support@1seoaudit.com</p>
            </div>
          </div>
        </div>
        {/* copyright */}
        <div className="copyright pt-5 pb-5">© Copyright 2025 by 1SEOAudit.com</div>
      </footer>


    </>
  );
};

export default Footer;
