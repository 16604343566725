import React from 'react';

const About = () => {
    return (
        <>
            <section className="starta-project my-5 py-md-5" id='features'>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-md-6 pe-md-5">
                            {/* Video BLock */}
                            <div className=" mb-4">
                                <img
                                    className="starta-block-img"
                                    src="https://attherate.dev/1seoaudit/1seoaudit-to-do-list.gif"
                                    alt="BG"
                                />
                               
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="starta-list list-unstyled">
                                        <li className="mb-3">
                                            <i className="fa-solid fa-circle-check" />
                                            Customized SEO Action Plan
                                        </li>
                                        <li className="mb-3">
                                            <i className="fa-solid fa-circle-check" />
                                            Competitor Analysis Insights
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="starta-list list-unstyled">
                                        <li className="mb-3">
                                            <i className="fa-solid fa-circle-check" />
                                            Increased Website Visibility
                                        </li>
                                        <li className="mb-3">
                                            <i className="fa-solid fa-circle-check" />
                                            Sustained Business Growth
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-space-between flex-wrap mt-5">
                                <button className="starta-button starta-button-2">
                                    <span className="position-relative">Happy Users</span>
                                    <span className="starta-button-hover" />
                                </button>
                                <div className="communityNumbers">
                                    <div className="communityImgs">
                                        <img
                                            src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg"
                                            alt="Community Member"
                                        />
                                        <img
                                            src="https://img.freepik.com/free-photo/serious-confident-asian-man-looking-camera_1262-5524.jpg"
                                            alt="Community Member"
                                        />
                                        <img
                                            src="https://img.freepik.com/free-photo/young-female-smiling-studio_23-2147847013.jpg"
                                            alt="Community Member"
                                        />
                                    </div>
                                    <article className="communityContent">
                                        <h6>We have delivered to</h6>
                                        <p>
                                            <span className="highlight">250+</span> Users
                                        </p>
                                    </article>
                                </div>
                            </div>
                           
                        </div>
                        <div className="col-md-6">
                            <h2 className="starta-heading">Secret SEO Checklist</h2>
                            <h1 className="starta-h1">
                            Secret Proven SEO To-Do List
                            </h1>
                            <p className="starta-desc">
                            Unlock our exclusive, proven SEO To-Do List, designed based on thorough competitor analysis and your website’s performance. We identify your competitors’ weaknesses and tailor a step-by-step action plan to enhance your rankings, boost organic traffic, and achieve sustainable business growth.
                            </p>
                            <img
                                className="starta-block-img mt-3 animate-img3"
                                src="https://i.postimg.cc/52X8F2LP/Growth-1.png"
                                alt="Block Img"
                            />
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default About;