import React from 'react';

const Loading = () => {
  return (
    <>
      <div class="loader"><span>1seoAudit.</span></div>
    </>
  );
};

export default Loading;
