import React from 'react';

const VideoSection = () => {
    return (
        <>
            <section className="starta-about pb-5 my-5">
                <div className="container position-relative" style={{ zIndex: 2 }}>
                    <div className="about-inner pt-4 position-relative">
                        {/* video Block */}
                        <div className="video-block animate-block">
                            <img
                                className="starta-block-img"
                                src="https://attherate.dev/1seoaudit/Our-Secret-SEO-To-Do-List.gif"
                                alt="BG"
                            />
                            {/* play button */}
                          
                            <h1 className="starta-h1 starta-h1-2 text-center mx-auto">
                            Get a Better Solution from an Expert
                            </h1>
                            <ul className="d-flex flex-wrap starta-list list-unstyled position-relative">
                                <li className="me-3">
                                    <i className="fa-solid fa-circle-check" />
                                    Complete SEO Audit Report
                                </li>
                                <li>
                                    <i className="fa-solid fa-circle-check" />
                                    Secret Proven SEO Checklist
                                </li>
                            </ul>
                           
                            {/* iframe */}
                            <iframe
                                src="https://youtu.be/PMneZxARm28?si=5XkNFyeGdrWwW8Rl"
                                title="Sample Video"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen=""
                            />
                            <i className="close fa-solid fa-circle-xmark" />
                        </div>
                        <div className="row align-items-center pt-5">
                            <div className="col-md-7 pt-3">
                                <h1 className="starta-h1">Expert Solutions for Your Success Journey</h1>
                                {/* clients  */}
                                <div className="clients">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/WebFlow.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/wix.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/joomla.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/web.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/wordpress.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/shopify.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/grocery-store.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-3">
                                            <div className="client">
                                                <img
                                                    src="assets/images/main/about/clients/html.png"
                                                    alt="Client"
                                                />
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <p className="starta-desc mt-sm-4">
                                Our free SEO audit service identifies website issues, optimizes performance, and boosts rankings. We provide detailed insights and actionable recommendations to improve your site's visibility, traffic, and overall search engine success.
                                </p>
                                <div className="row align-items-center">
                                   
                                 
                                    <ul className="starta-list list-unstyled">
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Receive a Comprehensive SEO Audit Report
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Access a Secret and Proven SEO Checklist
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Get Personalized Recommendations to Improve Rankings
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Gain Insights to Outperform Your Competitors
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            Benefit from Expert SEO Solutions
                                        </li>
                                    </ul>
                                </div>
                                {/* Contact Box */}
                                <div className="contact-box mt-5">
                                    <div className="row align-items-center">
                                        <div className="col-md-2 col-sm-2 col-2">
                                            <i className="fa-solid fa-envelope-open" />
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-10">
                                            <article>
                                                Got questions? We've got answers 
                                                 <a href="#"> support@1seoaudit.com</a>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* starta about bg */}
                <div className="starta-about-bg">
                    <img src="assets/images/main/about/aboutBG.png" alt="" />
                </div>
            </section>


        </>
    );
};

export default VideoSection;