// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAC7uI54kYvCgt6RQlwGVvafXCdhvD6PCc",
  authDomain: "seoaudit-ff222.firebaseapp.com",
  projectId: "seoaudit-ff222",
  storageBucket: "seoaudit-ff222.firebasestorage.app",
  messagingSenderId: "1020927373477",
  appId: "1:1020927373477:web:1596a9d8dfec86adc7dd4c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;